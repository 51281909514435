@import "../../../styles/helpers";

.successfully {
    padding-top: 40px; }

.title {
    margin-bottom: 32px;
    text-align: center; }

.info {
    max-width: 280px;
    margin: 0 auto 32px;
    text-align: center;
    @include body-bold-2;
    span {
        color: $p4; } }

.list {
    display: flex;
    flex-wrap: wrap;
    padding: 24px;
    border-radius: 12px;
    border: 1px solid $n6;
    @include m {
        padding: 24px 16px; }
    @include dark {
        border-color: $n3; } }

.item {
    &:first-child {
        margin-right: auto; }
    &:last-child {
        flex: 0 0 100%;
        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.category {
    margin-bottom: 10px;
    color: $n4; }

.content {
    font-weight: 500;
    color: $n2;
    @include dark {
        color: $n8; } }

.button {
    width: 100%;
    margin-top: 32px; }

