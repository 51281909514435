@import "../../styles/helpers";

.top {
    cursor: pointer;
    padding: 16px;
    background: $n8;
    border-radius: 4px;
    transition: 0.5s all;
    &:hover {
        box-shadow: 0px 0px 0px 2px $n6 inset; }
    @include dark {
        background: #18191D;
        &:hover {
            box-shadow: 0px 0px 0px 2px $n3 inset; } } }

.line {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    @include m {
        flex-wrap: wrap; } }

.title {
    margin-right: auto;
    @include m {
        flex: 0 0 100%;
        margin: 0 0 20px; } }

.wrap {
    display: flex;
    align-items: center;
    @include m {
        flex-wrap: wrap; } }

.form {
    position: relative;
    flex-shrink: 0;
    width: 256px;
    margin-right: 8px;
    @include d {
        width: 220px; }
    @include m {
        width: 100%;
        margin: 0 0 16px; } }

.input {
    width: 100%;
    height: 40px;
    padding: 0 40px 0 14px;
    border-radius: 20px;
    background: none;
    border: 2px solid $n6;
    @include poppins;
    @include caption-2;
    color: $n2;
    transition: border-color .2s;
    @include dark {
        border-color: $n3;
        color: $n8; }
    @include placeholder {
        color: $n4; }
    &:focus {
        border-color: $n4; } }

.result {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 40px;
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.dropdown {
    margin-right: 8px; }

.dropdownHead {
    height: 40px;
    line-height: 40px;
    background: $n6;
    border-radius: 20px;
    @include dark {
        background: $n3; } }

.info {
    margin-bottom: 4px;
    font-weight: 500; }

.currency {
    display: flex;
    align-items: center; }

.number {
    @include body-bold-1; }

.category {
    margin-left: 8px; }

.price {
    @include body-2;
    color: $n4; }

.head {
    padding: 20px 32px 8px;
    @include caption-2;
    font-weight: 500;
    color: $n4;
    @include m {
        padding: 20px 0 8px; } }

.body {
    background: $n8;
    border-radius: 4px;
    overflow: hidden;
    @include dark {
        background: #18191D; } }

.flag {
    position: relative;
    width: 48px;
    height: 48px;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%; }
    @include m {
        width: 32px;
        height: 32px; } }

.box {
    position: relative;
    .button {
        position: absolute;
        top: 36px;
        right: 16px;
        height: 24px;
        padding: 0 16px;
        border-radius: 12px; } }

.winner {
    background-color: #ffb800;
    color: #000;
    @include dark {
        color: #fff; } }

.winner:hover {
    background-color: #ffb800 !important; }
