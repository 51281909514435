@import "../../../styles/helpers";

.main {
    padding: 32px;
    background: $n8;
    border-radius: 4px;
    @include dark {
        background: #17181B; } }

.title {
    margin-bottom: 20px; }

.list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 3rem;
    justify-content: space-between;
    align-items: flex-start;
    @include m {
        grid-template-columns: 1fr 1fr; } }

.item {
    &.flex {
        display: flex;
        align-items: center;
        .number {
            color: $p4; } }
    &:not(:last-child) {
        @include m {
            margin-bottom: 16px; } } }

.info {
    margin-bottom: 4px;
    font-weight: 500;
    color: $n3;
    @include dark {
        color: $n4; } }

.currency {
    display: flex;
    align-items: center; }

.number {
    @include body-bold-1;
    @include t {
        font-size: 20px; }
    @include m {
        font-size: 24px; } }

.category {
    margin-left: 8px; }

.price,
.stage {
    @include body-2;
    color: $n4; }

.chart {
    margin-right: 24px; }

.box {
    position: relative;
    .button {
        position: absolute;
        top: 36px;
        right: 16px;
        height: 24px;
        padding: 0 16px;
        border-radius: 12px; } }
