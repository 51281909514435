@import "../../styles/helpers";

.head {
    padding: 20px 32px 8px;
    @include caption-2;
    font-weight: 500;
    color: $n4;
    @include m {
        padding: 20px 0 8px; } }

.body {
    background: $n8;
    border-radius: 4px;
    overflow: hidden;
    @include dark {
        background: #17181B; } }

.button {
    height: 32px;
    &:not(:last-child) {
        margin-right: 8px; } }
