@import "../../styles/helpers";

.page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    overflow: hidden; }

.inner {
    display: flex;
    flex-direction: column;
    flex-grow: 1; }
