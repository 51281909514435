@import "../../styles/helpers";

.footer {
    border-top: 1px solid $n6;
    @include dark {
        border-color: $n2; } }

.container {
    display: flex;
    @include m {
        display: block; } }

.body {
    @include m {
        position: relative;
        padding-top: 32px; } }

.col {
    flex: 0 0 30.5%;
    padding: 80px 86px 64px;
    @include x {
        padding: 80px 64px 64px; }
    @include d {
        flex: 0 0 34%; }
    @include t {
        padding: 64px 32px 48px; }
    @include m {
        padding: 32px 0;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n2; } }
    &:first-child {
        display: flex;
        flex: 0 0 39%;
        padding-left: 0;
        @include d {
            flex: 0 0 32%; }
        @include m {
            display: block; } }
    &:nth-child(3) {
        padding-right: 0; }
    &:nth-child(2),
    &:nth-child(3) {
        border-left: 1px solid $n6;
        @include m {
            border-left: none; }
        @include dark {
            border-color: $n2; } } }

.logo {
    display: inline-block;
    margin-right: 160px;
    @include x {
        margin-right: 80px; }
    @include t {
        margin-right: 32px; }
    @include m {
        margin: 0 0 32px; } }

.picDesktop {
    @include m {
        display: none; } }

.picMobile {
    display: none;
    @include m {
        display: block; } }

.item {
    .category {
        display: none;
        @include m {
            display: flex;
            align-items: center;
            margin-bottom: 0; }
        svg {
            margin-left: auto;
            fill: $n4;
            transition: transform .2s; } }
    &.active {
        @include m {
            .category {
                svg {
                    transform: rotate(180deg); } }
            .menu {
                display: flex; } } } }

.menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include m {
        display: none;
        padding-top: 40px; } }

.link {
    @include button-2;
    color: $n4;
    transition: color .2s;
    &:hover,
    &.active {
        color: $n2;
        @include dark {
            color: $n8; } }
    &:not(:last-child) {
        margin-bottom: 24px; } }

.category {
    margin-bottom: 41px;
    @include hairline-2; }

.info {
    @include caption-1;
    p {
        &:not(:last-child) {
            margin-bottom: 8px; } }
    &:not(:last-child) {
        margin-bottom: 24px; } }

.foot {
    padding: 25px 0 20px;
    border-top: 1px solid $n6;
    @include m {
        padding: 56px 0 48px;
        border: none; }
    @include dark {
        border-color: $n2; } }

.copyright {
    margin-right: auto;
    @include caption-2;
    color: $n4;
    @include m {
        margin: 0 0 24px; } }

.socials {
    display: flex; }

.social {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    svg {
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $n2;
            @include dark {
                fill: $n8; } } }
    &:not(:last-child) {
        margin-right: 24px; } }
